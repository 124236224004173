// Entry point for the build script in your package.json

// require("turbolinks").start()
// require("@rails/activestorage").start();
// require("channels");
// require("bootstrap/dist/js/bootstrap");
// require("@client-side-validations/client-side-validations");
// require("jquery");

import Rails from "@rails/ujs";
// Rails.start();
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
import "./jquery";
import "./toastr";
import "bootstrap";
import "@client-side-validations/client-side-validations";
import "./controllers"
import "@hotwired/turbo-rails"
import "./custom/smooth_scroll"
