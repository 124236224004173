import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('Connected to MapController')
    if (window.google && window.google.maps) {
      this.initMap()
    } else {
      window.initMap = () => this.initMap()
    }
  }

  disconnect() {
    if (window.map) {
      window.map = null
    }
  }

  initMap() {
    let orgsData = []
    try {
      orgsData = JSON.parse(this.element.dataset.mapOrgs || '[]')
    } catch (error) {
      console.error("Error parsing orgs data:", error)
      console.log("Raw data:", this.element.dataset.mapOrgs)
    }

    if (orgsData.length > 0) {
      this.renderMap(orgsData)
    } else {
      this.element.style.display = 'none'
    }
  }

  renderMap(orgs) {
    const location = orgs.length > 0
      ? { lat: parseFloat(orgs[0].latitude), lng: parseFloat(orgs[0].longitude) }
      : { lat: 45.523452, lng: -122.676207 }

    const map = new google.maps.Map(this.element, {
      center: location,
      zoom: 12
    })

    orgs.forEach((org) => {
      const marker = new google.maps.Marker({
        position: { lat: parseFloat(org.latitude), lng: parseFloat(org.longitude) },
        map: map
      })

      const infowindow = new google.maps.InfoWindow({
        content: `<div class='map_title'>
          <a href='#${org.id}'>${org.name}</a>
          <a href='/organizations/${org.slug}' class='text-muted' target='_blank'>
            <i class='fa fa-external-link-square text-muted'></i>
          </a>
        </div>`
      })

      infowindow.open(map, marker)
    })

    window.map = map
  }
}
