document.addEventListener('turbo:load', () => {
  const scrollArrow = document.querySelector('.bounce-arrow a');
  if (scrollArrow) {
    scrollArrow.addEventListener('click', (e) => {
      e.preventDefault();
      const target = document.querySelector('#soft-launch-scroll-target');
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth'
        });
      }
    });
  }
});
